<template>
  <v-container>
    <v-row>
      <v-col class="text-right">
        <v-dialog
            v-model="dialog"
            width="500"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="red lighten-2"
                dark
                v-bind="attrs"
                v-on="on"
                icon
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-form @submit.prevent="addPaidUser">
            <v-card>
              <v-card-title class="headline grey lighten-2">
                Insert user
              </v-card-title>
              <v-card-text>
                <v-col cols="12">
                  <v-text-field v-model="phone" label="Phone number"/>
                  <v-text-field v-model="amount" label="Amount"/>
                  <v-text-field v-model="note" label="Note"/>
                </v-col>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    type="submit"
                >
                  Save
                </v-btn>
                <v-btn
                    color="red"
                    text
                    type="button"
                    @click="dialog=false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
        <import-payments @updated="initialize"></import-payments>
        <export-subscribers></export-subscribers>
        <export-subscribers-without-progress/>
      </v-col>
    </v-row>
    <v-simple-table
        dense
    >
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">
            Name
          </th>
          <th class="text-left">
            Phone
          </th>
          <th class="text-left">
            Progress
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="item in users"
            :key="item.id"
        >
          <td>{{ item.name }}</td>
          <td>{{ item.phone }}</td>
          <td>{{ roundNumber(item.progress) }}%</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
import axios from "axios";
import ImportPayments from "../utilities/ImportPayments";
import Swal from "sweetalert2";
import ExportSubscribers from "../user/ExportSubscribers";
import ExportSubscribersWithoutProgress from "@/components/admin/user/ExportSubscribersWithoutProgress";

export default {
  name: "CourseUserList",
  components: {ExportSubscribersWithoutProgress, ExportSubscribers, ImportPayments},
  props: [],
  data: () => ({
    dialog: false,
    editMode: false,
    phone: '',
    amount: '',
    note: '',
    users: [],
  }),
  computed: {
    form() {
      let formData = new FormData()
      formData.append('course_id', this.$route.params.courseId)
      formData.append('phone', this.phone)
      formData.append('amount', this.amount)
      formData.append('note',this.note)
      return formData
    }
  },
  methods: {
    initialize() {
      const url = 'admin/courses/' + this.$route.params.courseId + '/subscribers'
      axios.get(url).then((response) => {
        this.users = response.data
      })
    },
    addPaidUser() {
      const url = 'payment';
      axios.post(url, this.form).then(() => {
        Swal.fire(
            'Successful!',
        );
        this.initialize()
        this.clear()
      }).catch((error) => {
        console.log(error);
      })
    },
    roundNumber(number) {
      return Number((number).toFixed(2))
    },
    clear() {
      this.dialog = false
      this.phone = ''
      this.amount = ''
    }
  },
  mounted() {
    this.initialize()
  }
}
</script>
<style scoped>

</style>